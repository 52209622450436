<template>
    <div class="tag-input__tag tw-bg-gray-100 tw-text-sm tw-cursor-pointer tw-p-2 tw-py-0.5 tw-rounded-full">
        <div class="tw-pr-2 tag tw-max-w-11/12" :title="tag">
            {{ tag }}
        </div>
        <div>
            <svg @click="setIndex" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 -5 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x tw-mt-0.5">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tag",
    components: {},
    props: {
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        tag: {
            type: String,
            required: true,
        },
    },
    methods: {
        setIndex() {
            this.$emit("click", this.index);
        },
    },
};
</script>

<style scoped>
.tag-input__tag {
    height: 24px;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    line-height: 24px;
    padding: 0 8px;
    display: flex;
    max-width: 12rem;
}
.tag {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 12rem;
}
</style>
