<template>
    <div class="tw-flex-row tw-w-full">
        <div class="tw-flex tw-justify-between tw-items-center tw-w-full tw-gap-4">
            <div v-if="condition" class="tw-flex tw-w-1/12 tw-mt-3">
                <label class="text-gray tw-text-xs"></label>
                <vue-multiselect class="tw-h-full research__multiselect" v-model="selectedDropDownItem" :options="dropDownOptions" :checkboxes="true" track-by="name" label="name" :close-on-select="false" :clear-on-select="true" :taggable="true" :searchable="true" @input="onSelect" :allowEmpty="false" />
            </div>
            <div class="tw-flex-rows tw-flex-1 tw-justify-between" v-for="fields in defaultField" :key="fields.value">
                <label class="tw-text-gray-500">{{ fields.name }}</label>
                <span class="tw-text-red-500 tw-pl-1" v-if="requiredFields.includes(fields.value)">*</span>
                <neo-tag-input v-model.trim="form[fields.value]" :tagsValue="tagList[fields.value]" @addTag="$emit('addTagT', $event, fields.value, tagList)" @removeLastTag="$emit('removeLastTag', $event, fields.value, tagList)" @removeTag="$emit('removeTagFromTagList', $event, fields.value, tagList)" @setSelectedField="$emit('setSelectedField', fields.value)" :placeholder="getPlaceholder(fields)" />
            </div>
            <!-- More Search options or Hide search options -->
            <div class="tw-flex-col tw-mt-4">
                <span class="tw-text-brand tw-text-sm tw-cursor-pointer" @click="toggleSearchOptions"
                    ><span>{{ toggle.moreSearchOptions ? "Hide" : "More" }} </span> search options</span
                >
                <!-- arrow up down -->
                <span class="tw-px-2">
                    <font-awesome-icon :icon="toggle.moreSearchOptions ? 'angle-up' : 'angle-down'" color="#0d69d5"> </font-awesome-icon>
                </span>
            </div>
            <!-- v-if="entityCount > 1" -->
            <div class="tw-flex-col tw-mt-4" v-if="deleteIcon">
                <neo-button icon="minus-circle" label="" class="remove-btn tw-h-10" padding="0.75rem 0.5rem" bg="white" color="var(--trash-color)" hoverText="var(--trash-hover-on)" @click="$emit('removeEntity', tagListIndex)" v-b-tooltip.hover.top title="Remove condition"> </neo-button>
            </div>
        </div>
        <div class="tw-flex tw-justify-between tw-items-center tw-w-full tw-gap-4 tw-py-2" v-if="toggle.moreSearchOptions">
            <div class="tw-flex-rows tw-flex-1 tw-justify-between" v-for="fields in showMoreField" :key="fields.value">
                <label class="tw-text-gray-500">{{ fields.name }}</label>
                <neo-tag-input v-model.trim="form[fields.value]" :tagsValue="tagList[fields.value]" @addTag="$emit('addTagT', $event, fields.value, tagList)" @removeLastTag="$emit('removeLastTag', $event, fields.value, tagList)" @removeTag="$emit('removeTagFromTagList', $event, fields.value, tagList)" @setSelectedField="$emit('setSelectedField', fields.value)" :placeholder="getPlaceholder(fields)" />
            </div>
        </div>
    </div>
</template>

<script>
import NeoTagInput from "../neo-tag-input";
import VueMultiselect from "@/components/vue-multiselect";
import Button from "@/components/button";

export default {
    name: "court-record-form",
    components: {
        "neo-tag-input": NeoTagInput,
        "neo-button": Button,
        VueMultiselect,
    },
    props: {
        tagList: {
            type: Object,
            required: true,
            default: () => {},
        },
        deleteIcon: {
            type: Boolean,
            default: false,
        },
        tagListIndex: {
            type: Number,
            required: false,
        },
        condition: {
            type: Boolean,
            default: false,
        },
        dropDownOptions: {
            type: Array,
            default: () => {},
        },
        requiredFields: {
            type: Array,
            default: () => [],
        },
        // selectedDropDownItem: {
        //     type: String,
        //     default: "",
        // },
    },
    data() {
        return {
            tags: [],
            toggle: {moreSearchOptions: false},
            form: {
                parties: "",
                location: "",
                attorney: "",
                judge: "",
                caseName: "",
                caseNumber: "",
            },
            defaultField: [
                {name: "Parties", value: "parties"},
                {name: "Location", value: "location"},
            ],
            showMoreField: [
                {name: "Attorney", value: "attorney"},
                {name: "Judge", value: "judge"},
                {name: "Case Name", value: "case_name"},
                {name: "Case Number", value: "case_number"},
            ],
            selectedDropDownItem: {name: "OR"},
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.tagList.condition_operator) {
                this.selectedDropDownItem = {name: this.tagList.condition_operator.name};
            }
        });
    },
    methods: {
        /**
         * @function
         * @name toggleSearchOptions()
         */
        toggleSearchOptions() {
            this.toggle.moreSearchOptions = !this.toggle.moreSearchOptions;
        },
        onTopTabChangeComment(tab) {
            this.active_tab = tab;
        },
        onSelect(value) {
            this.$emit("onSelectCondition", value.name, this.tagListIndex);
        },
        getPlaceholder(field) {
            if (!(field.value in this.tagList)) {
                return `Search for ${field.name}`;
            } else if (this.tagList[field.value].length <= 0) {
                return `Search for ${field.name}`;
            } else {
                return "";
            }
        },
    },
};
</script>
