<template>
    <div class="tw-flex tw-flex-col" :class="value === 'lexis' ? 'tw-flex-col-reverse tw-gap-2' : 'tw-my-2'">
        <div class="tw-flex">
            <div v-if="value !== 'lexis'" :class="{'tw-rotate-90': isExpanded, 'tw-cursor-pointer': !disabled}" class="tw-duration-200 tw-transform" @click="handleSourceExpand">
                <font-awesome-icon icon="angle-right"> </font-awesome-icon>
            </div>
            <input class="tw-cursor-pointer" :class="value !== 'lexis' ? 'tw-mx-4' : 'tw-mr-4'" v-model="isExpanded" type="checkbox" :value="value" @change="handleSourceChange" :disabled="disabled" />
            <span class="tw-text-black" v-text="title" />
        </div>

        <div v-if="isFilter" :class="[isExpanded ? 'tw-h-auto' : 'tw-transform -tw-translate-y-full tw-hidden']" class="tw-transition-all tw-duration-200">
            <div :class="value === 'lexis' ? '' : 'tw-py-5 tw-px-4'" v-if="value !== 'judyrecords'">
                <slot />
            </div>

            <div class="tw-w-full tw-text-center tw-border-0 tw-border-b tw-border-solid tw-border-gray-200" style="line-height: 0.1em" v-if="!['judyrecords', 'lexis'].includes(value)" />
        </div>
    </div>
</template>

<script>
export default {
    /**
     * @property
     * dynamic properties passed to the component with default value
     */
    props: {
        isExpanded: Boolean,
        title: {type: String, required: true},
        value: {type: String, default: ""},
        isFilter: {type: Boolean, default: true},
        disabled: {type: Boolean, default: false},
    },
    computed: {},
    methods: {
        /**
         * @method
         * @param {object} e - pointer event on the checkbox input
         */
        handleSourceChange(e) {
            this.$emit("handleSourceChange", e);
        },

        /**
         * @method - expand / collapse source
         */
        handleSourceExpand() {
            if (!this.disabled) this.$emit("handleSourceExpand");
        },
    },
};
</script>
