<template>
    <!-- :style="{ backgroundColor: 'var(--brand-area-color)' }" -->
    <div class="tag-input tw-min-h-8 tw-rounded-lg">
        <div v-if="tagsValue && tagsValue.length > 0">
            <div v-for="(tag, index) in tagsValue" :key="tag">
                <neo-tag :index="index" :tag="tag" @click="setIndex" />
            </div>
        </div>
        <input type="text" :placeholder="placeholder" class="tag-input__text" @keydown.enter="onEnter($event)" @keydown.delete="onDelete($event)" @keyup.enter="$emit('submit')" :disabled="disabled" @change="$emit('change', $event)" v-model="inputValue" />
    </div>
</template>

<script>
import Tag from "./tag.vue";
export default {
    name: "NeoTagInput",
    components: {
        "neo-tag": Tag,
    },
    data() {
        return {
            focus: false,
            shadow: true,
            inputValue: "",
        };
    },
    props: {
        placeholder: {type: String, default: "", required: false},
        prependIcon: {type: String, default: ""},
        disabled: {type: Boolean, default: false, required: false},
        tagsValue: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        setIndex(index) {
            // set index handler call for getting the index
            this.$emit("removeTag", index);
        },
        onEnter(event) {
            this.$emit("addTag", event);
            this.inputValue = "";
        },
        onDelete(event) {
            if (!this.inputValue) {
                this.$emit("removeLastTag", event);
            }
        },
    },
};
</script>

<style scoped>
/*tag input style*/

.tag-input {
    width: 100%;
    border: 1px solid #d9dfe7;
    background: #fff;
    border-radius: 10px;
    font-size: 0.9em;
    min-height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: "Roboto";
    margin-bottom: 10px;
}

.tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
    display: inline-block;
    margin-left: 8px;
}

.tag-input__text {
    border: none;
    outline: none;
    font-size: 1em;
    line-height: 40px;
    background: none;
    width: 100%;
}
</style>
