import { render, staticRenderFns } from "./corporate-records-sidepanel.html?vue&type=template&id=4ffceea9&scoped=true&"
import script from "./corporate-records-sidepanel.js?vue&type=script&lang=js&"
export * from "./corporate-records-sidepanel.js?vue&type=script&lang=js&"
import style0 from "./corporate-records-sidepanel.scss?vue&type=style&index=0&id=4ffceea9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffceea9",
  null
  
)

export default component.exports