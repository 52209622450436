<template>
    <neo-modal ref="confirmModal" :title="title" name="confirmation" :submitButtonText="submitButtonText" :loading="loading" width="30%" @submit="submitModal" @closed="cancelModal" v-show="showModal">
        <template #content v-if="message">
            <span style="white-space: break-spaces">{{ message }} </span>
        </template>

        <template #footer v-if="customFooter">
            <slot name="footer"></slot>
        </template>
    </neo-modal>
</template>

<script>
/* 
    Use for getting confirmation result.
    Example:
    const confirm = await this.$refs['modal-confirm'].show(config);
    config includes params: title, message, autoclose.
    Use autoclose: true to close 
*/
import Modal from "@/components/modal-content";

export default {
    name: "modal-confirm",
    components: {
        "neo-modal": Modal,
    },
    data() {
        return {
            title: "Confirmation",
            message: "",
            loading: false,
            autoclose: true,
            resolvePromise: undefined,
            rejectPromise: undefined,
            showModal: false,
        };
    },
    props: {
        submitButtonText: {
            type: String,
            default: () => "Confirm",
        },
        customFooter: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.autoclose = opts.autoclose ?? true;
            this.loading = false;
            this.showModal = true;
            this.$refs.confirmModal.showModal();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        submitModal() {
            this.resolvePromise(true);
            this.loading = true;
        },
        cancelModal() {
            this.resolvePromise(false);
            if (this.autoclose) this.$refs.confirmModal.hideModal();
        },
        close() {
            this.loading = false;
            this.$refs.confirmModal.hideModal();
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped></style>
