<template>
    <modal :name="modalName" :width="width" :maxWidth="maxWidth" height="auto" scrollable @closed="$emit('closed')" styles="border-radius: 10px;" v-bind="settings" :clickToClose="close" adaptive>
        <div class="modalContent tw-flex tw-flex-col" :class="{'modalContent--loading': loading || loadingGlobal}">
            <neo-loader v-if="loadingGlobal" class="tw-mr-2.5 globalLoader" :loading="true" :success="success" height="50px" width="50px" />
            <div class="modalContent__header tw-flex tw-justify-between">
                <!-- <div class="tw-inline-flex tw-w-full"> -->
                <h3 class="title">
                    {{ title }}
                </h3>
                <span class="tw-cursor-pointer cross" @click="hideModal">
                    <font-awesome-icon icon="times" size="lg" />
                </span>
                <!-- </div> -->
            </div>

            <div v-if="$slots.content" class="modalContent__content tw-overflow-y-auto tw-border-solid tw-border-0 tw-border-t tw-border-gray-300" :class="{'tw-bg-gray-100': darkContent}">
                <slot name="content">Modal content</slot>
            </div>
            <div class="modalContent__footer tw-flex tw-justify-end tw-border-solid tw-border-0 tw-border-t tw-border-gray-300" v-if="showFooter">
                <slot name="footer">
                    <neo-button v-if="additionalBtn" :label="additionalBtn" :disabled="disabled || loading || loadingGlobal" :bg="loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" padding="0.4rem 0.7rem" borderRadius="0.3rem" class="modalContent__footer__button tw-bottom-8 tw-right-8 tw-w-auto tw-mr-2.5" @click="$emit('alt-btn')" @keyup.enter="$emit('alt-btn')" />
                    <neo-button :label="submitButtonText" :disabled="disabled || loading || loadingGlobal" :bg="loading || loadingGlobal ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" padding="0.4rem 0.7rem" borderRadius="0.3rem" class="modalContent__footer__button tw-bottom-8 tw-right-8 tw-w-auto" @click="submitModal" @keyup.enter="submitModal">
                        <neo-loader v-if="loading" class="tw-mr-2.5" :loading="true" :success="success" height="15px" width="15px" />
                    </neo-button>
                </slot>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from "@/components/button";
import loader from "@/components/loader";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "modal-content",
    components: {
        "neo-button": Button,
        "neo-loader": loader,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {
        name: {
            type: String,
            default: () => "new-modal",
        },
        title: {
            type: String,
            default: () => "Modal Title",
        },
        close: {
            type: Boolean,
            default: () => false,
        },
        submitButtonText: {
            type: String,
            default: () => "Save",
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        loadingGlobal: {
            type: Boolean,
            default: () => false,
        },
        success: {
            // TODO check and remove
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        darkContent: {
            type: Boolean,
            default: () => false,
        },
        width: {
            type: String,
            default: () => "50%",
        },
        maxWidth: {
            type: Number,
        },
        settings: {
            type: Object,
            default: () => ({}),
        },
        additionalBtn: {
            type: String,
            default: () => "",
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        modalName() {
            return `modal-content--${this.name}`;
        },
    },
    mounted() {},
    methods: {
        showModal() {
            this.$modal.show(this.modalName);
        },
        hideModal() {
            this.$modal.hide(this.modalName);
        },
        submitModal() {
            this.$emit("submit");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../mixins.scss";
* {
    @include scrollBar;
}
.modalContent {
    &--loading {
        cursor: not-allowed;
        * {
            pointer-events: none;
        }
    }
    max-height: 90vh;
    & > * {
        padding: 25px 30px;
    }
    &__header {
        .title {
            color: var(--text-black-color);
            margin: 0;
            font-size: 18px;
            font-weight: normal;
            line-height: 22px;
        }
    }
    &__footer {
        padding-top: 20px;
        padding-bottom: 20px;
        &__button {
            min-width: 132px;
            min-height: 35px;
        }
    }
    .globalLoader {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}

.credit-packs {
    .modalContent {
        & > * {
            padding: 12px 24px;
        }
        &__header {
            @apply tw-bg-brand;
            @apply tw-text-white;
            .title {
                @apply tw-text-white;
            }
        }
    }
}
</style>
